<template>
  <transition name="fade">
    <div
      v-if="stack.length > 0"
      class="modal-overlay"
    >
      <div class="modal-content">
        <component
          :is="topModal.component"
          v-bind="topModal.props"
          @close="closeModal"
        />
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue';
import { useStackModal } from './use-stack-modal';

const modalStore = useStackModal();
const { stack } = modalStore;

const topModal = computed(() => stack.value[stack.value.length - 1]);

const closeModal = () => {
  modalStore.closeModal();
};
</script>

<style lang="scss" scoped>
@use "../../style/main" as *;

.modal-overlay {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgb(0, 0, 0, .5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 200;

  @include medium-up {
    align-items: center;
  }
}

.modal-content {
  position: relative;
  background: white;
  width: 100vw;
  height: calc(100% - 16px - env(safe-area-inset-top));
  border-radius: 12px 12px 0 0;
  padding-bottom: calc(32px + env(safe-area-inset-bottom));

  @include medium-up {
    width: 100%;
    height: 95%;
    max-width: 800px;
    border-radius: 12px;
  }
}

/* Fade Transition for Modal Overlay */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

/* Fade Transition for Modal Content (optional) */
.fade-content-enter-active,
.fade-content-leave-active {
  transition: transform .4s ease, opacity .4s ease;
}

.fade-content-enter-from,
.fade-content-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.fade-content-enter-to,
.fade-content-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
