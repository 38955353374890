<template>
  <div class="officehub-impersonation-bar">
    <div class="customer-name">
      {{ $t('customer.officehub.impersonation_bar.viewing_as', { name: customerName }) }}
    </div>
    <div
      class="close"
      :class="{ 'close--loading': showLoading }"
      @click="emit('close')"
    >
      <Icon
        class="close-icon"
        name="cross"
        :size="11"
      />
      <Icon
        class="loading-icon"
        name="loading"
        :size="16"
      />{{ $t('customer.officehub.impersonation_bar.close') }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import Icon from './Icon.vue';

const emit = defineEmits(['close']);

defineProps({
  customerName: {
    type: String,
    required: true,
  },
  showLoading: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

@keyframes rotate {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.officehub-impersonation-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  height: 40px;
  background: $color-semantic-info-500;
  align-items: center;
  padding: 0 16px;
}

.customer-name {
  @include overflow-ellipsis;

  @include font-body-med;
  color: white;
  flex: 1;
  text-align: center;
  padding-right: 8px;
  margin-right: -80px;
}

.close {
  @include font-body-med;
  color: white;
  display: flex;
  text-align: right;
  flex: 0 1 auto;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  cursor: pointer;

  .loading-icon {
    display: none;
    margin-top: -2px;
  }

  &--loading {

    .loading-icon {
      display: block;
      animation: rotate 1.5s infinite linear;
    }

    .close-icon {
      display: none;
    }
  }
}

.close-icon {
  margin-top: -2px;
}
</style>
