<template>
  <div
    class="direct-tag"
    :class="{'direct-tag--small': small}"
  >
    {{ $t('shared.direct_tag') }}
  </div>
</template>

<script>
export default {
  name: 'DirectTag',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@use "../../style/main" as *;

.direct-tag {
  @include font-body-reg;
  color: $color-grey-800;
  display: inline-flex;
  height: 20px;
  padding: 0 4px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid $color-grey-200;
  background: $color-grey-100;
  box-shadow: 0 2px 2px 0 rgba(31, 14, 74, .03);

  &--small {
    @include font-caption-reg;
    color: $color-grey-800;
    height: 16px;
  }
}
</style>
