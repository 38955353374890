<template>
  <div class="super-user-bar__wrapper">
    <div class="super-user-bar">
      <div class="super-user-bar__content">
        <slot />
      </div>
      <a
        v-if="!!link && !!linkText"
        :href="link"
        class="back-link"
      >
        {{ linkText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

.super-user-bar {
  height: 20px;
  background-color: $color-coral-600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  &__wrapper {
    height: 20px;
    position: relative;
  }

  &__content {
    font-size: 14px;
  }

  .back-link {
    position: absolute;
    right: 20px;
    text-decoration: none;
    font-weight: bold;
    color: white;
    font-size: 14px;
  }

  strong {
    font-weight: bold;
  }
}
</style>
