<template>
  <div class="wrapper">
    <img
      class="logo"
      :src="logo"
      alt="Officeguru logo"
    >
  </div>
</template>

<script>
import officeguruLogo from '../../../assets/og-avatar-dark.svg';

export default {
  name: 'ConversationsOgIcon',
  data() {
    return {
      logo: officeguruLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
@use "../../../style/main" as *;

.wrapper {
  display: flex;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.logo {
  width: 100%;
  height: 100%;
}
</style>
