<template>
  <div data-cy="navigation" class="navigation">
    <div class="main">
      <router-link class="logo-link" to="/" data-cy="logo">
        <Logo class="logo" />
      </router-link>
    </div>

    <div class="sub">
      <p class="description">
        Bryggervangen 55, 4. tv.<br />
        2100 København Ø<br />
        Tel: <a href="tel:+4543991529">43 99 15 29</a>
      </p>
    </div>
  </div>
</template>

<script>
import { Logo } from '@officeguru/components-vue3';

export default {
  components: {
    Logo,
  },
};
</script>

<style lang="scss" scoped>
@use 'src/style/main' as *;

.navigation {
  display: flex;
  flex-direction: column;
  width: 208px;
  background: $color-grey-900;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  margin-left: 0;
  transition: margin 0.1s ease-in-out, height 0.1s ease-in-out;
  z-index: 100;
}

.main {
  display: flex;
  flex-direction: column;
}

.sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
}

.description,
.description a {
  @include font-body-med;
  color: $color-grey-200;
  text-align: center;
  text-decoration: none;
}

.logo-link {
  display: flex;
  width: 100%;
  justify-content: center;
}

.logo {
  width: 40px;
  padding: 40px 0;
}
</style>
