<template>
  <div
    :style="`background: ${colors.light}`"
    class="service-icon-wrapper"
  >
    <Icon
      name="service_unverified"
      :size="calculatedIconSize"
      :style="`color: ${colors.dark}`"
    />
    <DirectTag
      v-if="isDirect"
      class="direct"
      :small="calculatedIconSize < 21"
    />
  </div>
</template>

<script>
import Icon from './Icon.vue';
import DirectTag from './direct/direct-tag.vue';

export default {
  name: 'ServiceIconUnverified',
  components: { Icon, DirectTag },
  props: {
    calculatedIconSize: {
      type: Number,
      required: true,
    },
    isDirect: {
      type: Boolean,
    },
  },
  data() {
    return {
      colors: {
        light: 'rgba(97, 110, 255, 0.06)',
        dark: 'rgb(97, 110, 255)',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

.service-icon-wrapper {
  position: relative;
}

.direct {
  position: absolute;
  bottom: 0;
}
</style>
