<template>
  <Async
    v-if="needs"
    data-cy="page"
    :needs="needs"
    :load-on-update="loadOnUpdate"
  >
    <template #default="slotProps">
      <div
        class="page"
        :class="{
          'page--no-sidebar': noSidebar,
        }"
      >
        <template v-if="hasSubNav">
          <SubNavigation data-cy="subnavigation">
            <slot
              :data="slotProps"
              name="subnav"
            />
          </SubNavigation>
        </template>
        <div v-if="hasSubSubNav">
          <slot
            :data="slotProps"
            name="subsubnav"
          />
        </div>
        <div
          class="page__inner"
          :class="{
            'page__inner--small-padding': smallPadding,
            'page__inner--no-padding': noPadding,
            'page__inner--full-width': fullWidth,
          }"
        >
          <div
            v-if="hasPageHeader"
            class="page__header"
          >
            <slot
              :data="slotProps"
              name="header"
            />
          </div>
          <slot :data="slotProps" />
        </div>
      </div>
    </template>
    <template #loading>
      <div class="page__loader">
        <Spinner />
      </div>
    </template>
    <template #failed>
      <div class="page__error">
        <p>{{ $t('shared.something_went_wrong') }}</p>
      </div>
    </template>
  </Async>
  <div
    v-else
    data-cy="page"
    class="page"
  >
    <template v-if="hasSubNav">
      <SubNavigation data-cy="subnavigation">
        <slot name="subnav" />
      </SubNavigation>
    </template>
    <div class="page__inner">
      <div
        v-if="hasPageHeader"
        class="page__header"
      >
        <slot name="header" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import Async from './async.vue';
import SubNavigation from './sub-navigation.vue';
import Spinner from './Spinner.vue';

export default {
  components: {
    Spinner,
    SubNavigation,
    Async,
  },
  props: {
    needs: { type: Object, required: false, default: null },
    loadOnUpdate: { type: Boolean, default: false },
    noSidebar: { type: Boolean },
    smallPadding: { type: Boolean },
    noPadding: { type: Boolean },
    fullWidth: { type: Boolean, default: false },
  },
  computed: {
    hasSubNav() {
      return this.$slots.subnav;
    },
    hasSubSubNav() {
      return this.$slots.subsubnav;
    },
    hasPageHeader() {
      return this.$slots.header;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

.page {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 192px;
  flex: 1 1 100%;
  align-items: center;

  .navigation-collapsed & {
    margin-left: 50px;
  }

  &--no-sidebar {
    margin-left: 0 !important;
    width: 100%;
  }
}

.page__inner {
  padding: 32px;
  flex: 1;
  width: 100%;
  max-width: 1264px; // + padding * 2

  &--full-width {
    max-width: none;
  }

  &--small-padding {
    padding: 16px;
  }

  &--no-padding {
    padding: 0px;
  }

  @include small-down {
    padding: 20px;
  }
}

.page__header {
  margin-bottom: 32px;

  @include small-down {
    margin-bottom: 14px;
  }
}

.page__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.page__error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 18px;
}
</style>
