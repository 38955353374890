import { computed } from 'vue';
import store from '@/store';
import Bugsnag from '@bugsnag/js';

function useDiscoveryPage() {
  const marketplaceAppUrl = import.meta.env.VITE_MARKETPLACE_APP_URL;

  if (!marketplaceAppUrl) {
    Bugsnag.notify(new Error('VITE_MARKETPLACE_APP_URL is not defined'));
  }

  // keys are country_locale
  const configuration = {
    dk_da: 'da/udforsk?embedded',
    dk_en: 'en/discover?embedded',
    de_de: 'de/entdecken?embedded',
    de_en: 'de_en/discover?embedded',
  };

  const discoveryPageLink = computed(() => {
    const locale = store.getters['settings/language']?.toLowerCase();
    const country = store.getters['settings/country']?.toLowerCase();
    const countryAndLocale = `${country}_${locale}`;

    const localizedPath = configuration[countryAndLocale];
    if (!localizedPath) {
      Bugsnag.notify(
        new Error(`No localized path found for locale: ${countryAndLocale}`)
      );
    }

    return `${marketplaceAppUrl}${localizedPath}`;
  });

  return {
    discoveryPageLink,
  };
}

export { useDiscoveryPage };
