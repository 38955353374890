<template>
  <div style="width: 100%">
    <template v-if="link.children">
      <details :open="childIsActive(link)">
        <summary>
          <span
            class="menu-link"
            data-cy="cmp-navigation-item"
          >
            <NavigationItemContent
              :link="link"
              :has-children="true"
            />
          </span>
        </summary>
        <div>
          <NavigationItem
            v-for="(child, i) in link.children"
            :key="i"
            :link="child"
            :is-child="true"
            class="child-link"
          />
        </div>
      </details>
    </template>
    <template v-else-if="link.to && link.to.indexOf('http') !== -1">
      <a
        :key="link.order"
        :href="link.to"
        class="menu-link"
        :class="{ distanced: link.distanced }"
        data-cy="cmp-navigation-item"
        :target="link.blank ? '_blank' : ''"
        :rel="link.blank ? 'noopener nofollow' : ''"
        @click="$emit('hide-mobile-navigation')"
      >
        <NavigationItemContent
          :link="link"
        />
      </a>
    </template>
    <router-link
      v-else
      :key="link.order"
      v-slot="{ isActive, isExactActive }"
      class="menu-link"
      :to="link.to"
      :class="{ distanced: link.distanced }"
      data-cy="cmp-navigation-item"
      @click="$emit('hide-mobile-navigation')"
    >
      <NavigationItemContent
        :link="link"
        :is-active="isActive"
        :is-exact-active="isExactActive"
      />
    </router-link>
  </div>
</template>
<script>
import NavigationItemContent from './navigation-item-content.vue';

export default {
  name: 'NavigationItem',
  components: { NavigationItemContent },
  props: {
    link: {
      type: Object,
      required: true,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hide-mobile-navigation'],
  methods: {
    childIsActive(link) {
      if (link.children) {
        return link.children.some((child) => this.$route.path.includes(child.to));
      }
      return this.$route.path.includes(link.to);
    },
  },
};
</script>

<style scoped lang="scss">
@use "../style/main" as *;

.menu-link {
  @include font-body-reg;
  color: $color-grey-700;
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  text-decoration: none;
  will-change: color;
  transition: font .2s ease-in-out, color .2s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.menu-link-icon {
  margin-right: 12px;
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

details[open] summary .open-icon {
  transform: rotate(180deg);
}

.child-link a {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
</style>
