<template>
  <div
    data-cy="cmp-spinner"
    :class="rootClass"
  >
    <div /><div /><div /><div />
  </div>
</template>

<script>
export default {
  props: {
    small: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
  },
  computed: {
    rootClass() {
      return {
        spinner: true,
        'spinner--small': this.small,
        'spinner--light': this.light,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

$spinnerColor: $color-semantic-info-500; // lighten($color-grey-900, 60%);
$small: null;
$light: null;

@keyframes spinner {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  &--small {
    $small: & !global;

    width: 20px;
    height: 20px;
  }

  &--light {
    $light: & !global;
  }
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid $spinnerColor;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border-color: $spinnerColor transparent transparent transparent;
}

#{$small} div {
  width: 20px;
  height: 20px;
  margin: 0;
  border: 2px solid;
  border-color: $spinnerColor transparent transparent transparent;
}

#{$light} div {
  border-color: white transparent transparent transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -.15s;
}
</style>
