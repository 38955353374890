<template>
  <Page
    data-cy="view-conversations-overview"
    no-padding
    full-width
    :needs="{}"
  >
    <div :class="conversationsClass">
      <div class="list">
        <ConversationsList
          :bulk-sending="bulkSending"
          :conversations="conversations"
          :conversations-list-meta="conversationsListMeta"
          :filter-models="queryModels"
          :results-limited="queryManager.isLimitingResults()"
          :conversations-loading="requestManager.loading"
          :use-handling="useHandling"
          @apply-filters="onApplyFilters"
          @clear-filters="onClearFilters"
          @load-more-conversations="onLoadMoreConversations"
          @bulk-button-pressed="$emit('bulk-button-pressed')"
          @interface="setConversationListInterface"
        />
      </div>
      <div class="chat">
        <router-view />
      </div>
    </div>
  </Page>
</template>

<script>
import { QueryManager } from '@officeguru/webapp-shared/src/helpers/request/query-manager';
import { RequestManager } from '@officeguru/webapp-shared/src/helpers/request/request-manager';
import { QueryContactPersons } from '@officeguru/webapp-shared/src/helpers/request/queries/query-contact-persons';
import { QueryServices } from '@officeguru/webapp-shared/src/helpers/request/queries/query-services';
import { QueryCustomers } from '@officeguru/webapp-shared/src/helpers/request/queries/query-customers';
import { QueryPartners } from '@officeguru/webapp-shared/src/helpers/request/queries/query-partners';
import { sortBy } from 'lodash';
import { QueryIsVerified } from '@officeguru/webapp-shared/src/helpers/request/queries/query-is-verified';
import { QueryIsHandled } from '@officeguru/webapp-shared/src/helpers/request/queries/query-is-handled';
import { QueryOgDirect } from '@officeguru/webapp-shared/src/helpers/request/queries/query-direct';
import Page from '../../../components/page.vue';
import ConversationsList from '../components/conversations-list.vue';

export default {
  name: 'ConversationsListPage',
  components: {
    ConversationsList,
    Page,
  },
  inject: ['api'],
  props: {
    useHandling: {
      type: Boolean,
    },
    bulkSending: {
      type: Boolean,
    },
  },
  emits: ['bulk-button-pressed', 'interface'],
  data() {
    return {
      queryManager: new QueryManager(),
      requestManager: new RequestManager(this.api),
      queryModels: {
        services: new QueryServices(),
        partners: new QueryPartners(),
        customers: new QueryCustomers(),
        isVerified: new QueryIsVerified(),
        contactPersons: new QueryContactPersons(),
        isHandled: new QueryIsHandled(),
        og_direct: new QueryOgDirect(),
      },
      conversationListInterface: {},
    };
  },
  computed: {
    conversationsClass() {
      return {
        conversations: true,
        'conversation-selected': this.$route.params.id,
      };
    },
    conversations() {
      const conversations = this.$store.getters['conversations/list'];
      const support = conversations.find((c) => c.is_support);
      const withoutSupport = conversations.filter((c) => !c.is_support);
      const unreadWithoutSupport = withoutSupport.filter((c) => c.latest_log?.read);
      const readWithoutSupport = withoutSupport.filter((c) => !c.latest_log?.read);

      // support first, then unreads sorted by `created_at`, then reads sorted by `created_at`
      return [
        ...(support ? [support] : []),
        ...sortBy(readWithoutSupport, ['latest_log.created_at']).reverse(),
        ...sortBy(unreadWithoutSupport, ['latest_log.created_at']).reverse(),
      ];
    },
    conversationsListMeta() {
      return this.$store.getters['conversations/listMeta'];
    },
  },
  async created() {
    this.$store.commit('conversations/clearList');

    this.queryManager
      .addQueries(Object.values(this.queryModels))
      .restorePersistedQueryValues(this.$route.query);

    this.fetchConversations(true);

    this.showBeamerContainer(false);

    await this.$nextTick();

    this.$emit('interface', {
      refresh: () => this.fetchConversations(true),
      goToFirstConversation: () => {
        this.conversationListInterface.goToFirstConversation(true);
      },
    });
  },
  beforeUnmount() {
    this.showBeamerContainer(true);
  },
  methods: {
    showBeamerContainer(show) {
      const container = document.getElementsByTagName('body')[0];

      if (!container) return;

      if (show) {
        container.classList.remove('beamer-hidden');
      } else {
        container.classList.add('beamer-hidden');
      }
    },
    onApplyFilters(filters) {
      Object.keys(filters).forEach((filterName) => {
        this.queryModels[filterName][filterName] = filters[filterName];
      });
      this.fetchConversations(true);
    },
    onClearFilters() {
      this.queryManager.resetQueriesValues();
      this.fetchConversations(true);
    },
    onLoadMoreConversations() {
      this.fetchConversations();
    },
    fetchConversations(resetPage = false) {
      this.queryManager.persistQueryValues(this.$router);

      this.$store.dispatch('conversations/get_list', {
        requestManager: this.requestManager,
        queryManager: this.queryManager,
        resetPage,
      });
    },
    setConversationListInterface(value) {
      this.conversationListInterface = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../../../style/main" as *;

.conversations {
  display: flex;
  transition: transform .15s linear;
  overflow: hidden;
  min-height: 100vh;

  @include small-down {
    width: calc(200% + 20px);
  }

  &.conversation-selected {
    @include small-down {
      transform: translateX(calc(-50% - 10px));
    }
  }
}

.chat {
  background: #fff;
  min-height: 100vh;
  flex: 1;
}

.list {
  min-height: 640px;
  max-width: 312px;
  flex: 1;
  border-right: 1px solid $color-grey-200;

  @include small-down {
    height: calc(100vh - 160px);
    max-width: 100%;
    margin-right: 4px;
  }
}
</style>
