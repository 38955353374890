<template>
  <div
    v-if="show"
    class="load-more-button"
  >
    <ButtonV2
      type="secondary"
      icon-left="refresh"
      :disabled="disabled"
    >
      {{
        loadMoreText
      }}
    </ButtonV2>
  </div>
</template>

<script>
import ButtonV2 from './ButtonV2.vue';

export default {
  name: 'LoadMoreButton',
  components: {
    ButtonV2,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadMoreText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

.load-more-button {
  display: flex;
  justify-content: center;
}
</style>
