<template>
  <div
    ref="list"
    data-cy="cmp-image-list"
    :class="rootClass"
  >
    <div
      v-for="(image, index) in visibleImages"
      :key="index + image.src"
      class="image"
      @click="openImage(index)"
    >
      <div class="inner">
        <img
          class="thumb"
          :src="image.thumbnail"
        >
        <div
          v-if="index === visibleImages.length - 1 && extraImages"
          class="extra"
        >
          +{{ extraImages }}
        </div>
      </div>
    </div>
    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <img
        class="bigimage"
        :src="bigImage"
      >
      <div class="controls">
        <IconButton
          icon="arrow-left"
          @click="go(-1)"
        />
        <IconButton
          icon="arrow-right"
          @click="go(1)"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal.vue';
import IconButton from './IconButton.vue';

export default {
  components: {
    IconButton,
    Modal,
  },
  props: {
    images: { type: Array, required: true },
  },
  data() {
    return {
      modalOpen: false,
      currentIndex: 0,
      wrap: 0,
    };
  },
  computed: {
    extraImages() {
      return this.images.length - this.visibleImages.length;
    },
    visibleImages() {
      return this.images.slice(0, this.wrap);
    },
    rootClass() {
      return {
        imagelist: true,
      };
    },
    bigImage() {
      return this.images[this.currentIndex].src;
    },
  },
  mounted() {
    this.wrapImages();
    window.addEventListener('resize', this.wrapImages);
  },
  unmounted() {
    window.removeEventListener('resize', this.wrapImages);
  },
  methods: {
    openImage(index) {
      this.currentIndex = index;
      this.$nextTick(() => {
        this.modalOpen = true;
      });
    },
    wrapImages() {
      const visible = Math.floor((this.$refs.list.scrollWidth + 8) / 108);
      this.wrap = visible || 1;
    },
    go(dir) {
      const newIndex = this.currentIndex + dir;
      this.currentIndex = newIndex < 0 ? this.images.length - 1 : newIndex % this.images.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

.imagelist {
  display: flex;
  flex-wrap: wrap;
}

.image {
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  display: inline-flex;
  max-width: 150px;
  min-width: 100px;

  & + & {
    margin-left: 8px;
  }
}

.inner {
  padding-top: 100%;
  position: relative;
  width: 100%;
}

.thumb,
.extra {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.bigimage {
  max-width: 100%;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.extra {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color-grey-900, .5);
  font-size: 28px;
  font-weight: 500;
  color: $color-grey-100;
  pointer-events: none;
}
</style>
