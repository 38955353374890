<template>
  <div
    data-cy="input-file"
    :class="rootClass"
  >
    <VueUploadComponent
      ref="upload"
      class="dropzone"
      :class="{ 'dropzone--active': $refs.upload && $refs.upload.dropActive }"
      multiple
      :drop="true"
      :input-id="inputId"
      @input-file="upload"
    >
      <Icon
        class="dropzone__icon"
        name="upload"
        :size="14"
      />

      {{ $t("shared.input_file.drag_file_here_or_click_to_upload") }}
    </VueUploadComponent>

    <UploadItem
      v-for="(item, index) in files"
      :key="index"
      class="upload-item"
      :file="item.file"
      :progress="item.progress"
      :error="item.error"
      @delete="removeFile(index)"
    />
  </div>
</template>

<script>
import VueUploadComponent from 'vue-upload-component';
import UploadItem from './UploadItem.vue';
import Icon from './Icon.vue';

export default {
  compatConfig: {
    MODE: 3,
  },
  name: 'InputFile',
  components: {
    Icon,
    UploadItem,
    VueUploadComponent,
  },
  props: {
    inputId: {
      type: String,
      default: 'file1',
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    publicId: { type: [String, null], default: null },
    usePublicApi: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'uploading', 'removed'],
  data() {
    return {
      files: this.modelValue.map((media) => ({
        file: {
          url: media.sizes?.tiny || media.url,
          title: media.name,
        },
        progress: 100,
        error: null,
        media,
      })),
    };
  },
  computed: {
    rootClass() {
      return {};
    },
    uploadedFiles: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    modelValue: {
      handler(files) {
        if (files.length === 0) {
          this.files = [];
        }
      },
      deep: true,
    },
  },
  beforeUnmount() {
    this.$store.dispatch('media/cancelAllUploads');
  },
  methods: {
    upload({ file }) {
      const index = this.files.length;
      this.files.push({
        file: {
          url: URL.createObjectURL(file),
          title: file.name,
        },
        progress: 0,
        error: null,
      });

      this.$store
        .dispatch(this.usePublicApi ? 'media/publicUpload' : 'media/upload', {
          file,
          onProgress: (progress) => {
            if (this.files[index]) {
              this.files[index].progress = progress === 100 ? 99.9 : progress;
            }
          },
          publicId: this.publicId,
          isPublic: this.isPublic,
        })
        .then((media) => {
          if (this.files[index]) {
            this.files[index].media = media;
            this.files[index].progress = 100;
          }

          this.uploadedFiles = [...this.uploadedFiles, media];

          this.isUploading();
        })
        .catch((error) => {
          if (this.files[index]) {
            if (error.response) {
              this.files[index].error = error.response.data.message;
            } else {
              this.files[index].error = this.$t('shared.something_went_wrong');
            }
          }

          this.isUploading();
        });

      this.isUploading();
    },
    removeFile(index) {
      if (this.files[index].media) {
        this.$store.dispatch(this.usePublicApi ? 'media/publicDelete' : 'media/delete', {
          media: this.files[index].media,
          publicId: this.publicId,
        });
        this.uploadedFiles.splice(
          this.uploadedFiles.findIndex((m) => m.id === this.files[index].media.id),
          1,
        );
      }

      this.files.splice(index, 1);
      this.isUploading();
      this.$emit('removed', this.modelValue);
    },
    isUploading() {
      const uploadInProgress = this.files?.some((file) => file.progress < 99);
      this.$emit('uploading', uploadInProgress);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../style/main" as *;

.upload-item {
  margin-top: 10px;
}

.dropzone {
  @include font-body-med;
  color: $color-grey-500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border: 1px dashed $color-grey-200;
  border-radius: 5px;
  padding: 12px 16px;
  transition: border-color 300ms, color 300ms;

  :deep(label) {
    cursor: pointer;
  }

  &:hover,
  &--active {
    border-color: $color-grey-900;
    color: $color-grey-900;
  }
}

.dropzone__icon {
  margin-right: 8px;
}

.input-file__file {
  margin-top: 8px;
}
</style>
